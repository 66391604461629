import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '../src/style.css'
import Fr from '@/locale/Fr.json'
import Ar from '@/locale/Ar.json' 
import { createI18n } from 'vue-i18n'
import VueCarousel from 'vue-carousel';


// import '../src/assets/vendor/jquery/jquery.min.js'
import jQuery from 'jquery';
// import $ from 'jquery'
window.$ = window.jQuery = jQuery; // Ajouter jQuery à l'objet window



import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'


import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/owl.carousel.js'
// import '../src/assets/css/plugins.css'

import '../src/assets/fonts/flaticon/flaticon.css'
import '../src/assets/fonts/fontawesome/fontawesome-all.min.css'


import '../src/assets/css/style.css'
import '../src/assets/css/maincolors.css'


import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';


const i18n = createI18n({
    locale:localStorage.getItem("lngAb") || 'Fr',
    legacy: false,
    messages:{
        Fr:Fr,
        Ar:Ar
    }
  })

createApp(App).use(i18n).use(ToastPlugin, { duration: 3000 }).use(router).use(VueCarousel).mount('#app')