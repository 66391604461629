import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'Home',

    component: () => import( '@/views/Home.vue')
  },
  {
    path: '/testelements',
    name: 'TestElements',

    component: () => import( '@/views/testElements.vue')
  },
  {
    path: '/associations',
    name: 'Associations',

    component: () => import( '@/views/Associations/Association.vue')
  },
  {
    path: '/medecins',
    name: 'Medecins',

    component: () => import( '@/views/Medecins/Medecins.vue')
  },
  {
    path: '/blog',
    name: 'Blog',

    component: () => import( '@/views/Blog/Blog.vue')
  },
  {
    path: '/article/:slug',
    name: 'SingleBlog',

    component: () => import( '@/views/Blog/SingleBlog.vue')
  },
  {
    path: '/forum',
    name: 'Forum',

    component: () => import( '@/views/Forum/Forum.vue')
  },
  {
    path: '/sujet/:slug',
    name: 'SingleSujet',

    component: () => import( '@/views/Forum/SingleSujet.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
